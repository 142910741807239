<script>
import { ArrowUpIcon } from "vue-feather-icons";
import { Carousel, Slide } from "vue-carousel";

import Navbar from "@/components/navbar";
import Switcher from "@/components/switcher";
import Footer from "@/components/footer";

/**
 * Page-services component
 */
export default {
  data() {
    return {};
  },
  components: {
    Navbar,
    Switcher,
    Footer,
    Carousel,
    Slide,
    ArrowUpIcon,
  },
};
</script>

<template>
  <div>
    <Navbar />

    <!-- Hero Start -->
    <section class="bg-half bg-light d-table w-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level">
              <h4 class="title">Entreprise Networks</h4>
              <div class="page-next">
                <nav aria-label="breadcrumb" class="d-inline-block">
                  <ul class="breadcrumb bg-white rounded shadow mb-0">
                    <li class="breadcrumb-item">
                      <router-link to="/">Services</router-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Entreprise Networks
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <!-- Shape Start -->
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg
          viewBox="0 0 2880 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    <!--Shape End-->

    <!-- Feature Start -->
    <section class="section">
      <div class="container">
        <div class="row text-center features p-3">
          <p class="h3">
            The Enterprise Network is the backbone of every organization that's
            why our expert engineers can install, manage and support your
            network 24/7.
          </p>
        </div>
        <div class="row">
          <!--end col-->

          <div class="col-md-4 col-12 mt-5">
            <div class="features">
              <div class="image position-relative d-inline-block">
                <img
                  src="images/icon/access-point.svg"
                  class="avatar avatar-small"
                  alt=""
                />
              </div>

              <div class="content mt-4">
                <h4 class="title-2">Wireless Networks</h4>
                <p class="text-muted mb-0">
                  Our wireless networks meet the needs of all users, giving them
                  complete freedom and flexibility and mobility within your
                  premises.<br />
                  <router-link to="/contact" class="text-primary"
                    >Ask about our Free Wireless Network Surveys.</router-link
                  >
                </p>
              </div>
            </div>
          </div>
          <!--end col-->

          <!--end col-->

          <div class="col-md-4 col-12 mt-5">
            <div class="features">
              <div class="image position-relative d-inline-block">
                <img
                  src="images/icon/security-network.svg"
                  class="avatar avatar-small"
                  alt=""
                />
              </div>

              <div class="content mt-4">
                <h4 class="title-2">Network Security</h4>
                <p class="text-muted mb-0"></p>
              </div>
            </div>
          </div>
          <!--end col-->

          <!--end col-->

          <div class="col-md-4 col-12 mt-5">
            <div class="features">
              <div class="image position-relative d-inline-block">
                <img
                  src="images/icon/computer.svg"
                  class="avatar avatar-small"
                  alt=""
                />
              </div>

              <div class="content mt-4">
                <h4 class="title-2">Data Center Networking</h4>
                <p class="text-muted mb-0">
                  We create, design, and implement highly available and scalable
                  data center networking solutions.
                </p>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->

      <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4"></h4>
              <p class="text-muted para-desc mx-auto mb-0">
                Contact us to learn how PromiTech Solutions can help modernize
                your infrastructure and prepare you to scale accordingly.
                Whether you are looking for on-prem, cloud or colo,
                <span class="text-primary font-weight-bold">PromiTech</span> can
                tailor the proper solutions for your business needs
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Feature Start -->

    <!-- Project Start -->

    <!--end section-->
    <!-- Project End -->
    <!--end section-->
    <Footer />
    <!-- Footer End -->
    <!--<Switcher /> -->
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-soft-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>
